/** @jsx jsx */
import { jsx } from "theme-ui"

export default () => (
  <div
    sx={{
      height: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      p: { mb: 20 },
    }}
  >
    <h2 sx={{ fontSize: [32, 45], mb: 20 }}>Blog Page</h2>
    <p>
      I need you to create an account at{" "}
      <a href="https://contentful.com">Contentful.com</a> so I can set up your
      blog.
    </p>
    <p>
      <strong>Website TO DO</strong>
    </p>
    <ul sx={{ listStyleType: "disc", lineHeight: 2 }}>
      <li>Finalize / proofread copy</li>
      <li>Finalize photos used on the service pages</li>
      <li>Finalize list of certications for each Service</li>
      <li>Finalize usable raves (send me your version of that doc)</li>
      <li>Add blog post and blog post authoring account</li>
      <li>Test on various screen sizes / browsers</li>
      <li>Add your mailchimp account and an email subscribe form</li>
      <li>Any last design edits you want to make</li>
    </ul>
  </div>
)
